<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Profile</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Profile</span>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 lg:col-6 lg:col-offset-3">
        <div class="card bg-white pt-4">
          <form @submit.prevent="handleSubmit(!v$.$invalid)">
            <div class="formgrid grid">
              <div class="field col-12">
                <label
                  :class="{ 'p-error': v$.form.name.$invalid && submitted }"
                  >Nama</label
                >
                <Skeleton v-if="isLoading" width="100%" height="35px" />
                <InputText
                  v-else
                  v-model="v$.form.name.$model"
                  type="text"
                  :class="{ 'p-invalid': v$.form.name.$invalid && submitted }"
                  class="w-full"
                />
                <small
                  v-if="
                    (v$.form.name.$invalid && submitted) ||
                    v$.form.name.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.name.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-6">
                <label
                  :class="{ 'p-error': v$.form.username.$invalid && submitted }"
                  >Username</label
                >
                <Skeleton v-if="isLoading" width="100%" height="35px" />
                <InputText
                  v-else
                  v-model="v$.form.username.$model"
                  type="text"
                  :class="{
                    'p-invalid': v$.form.username.$invalid && submitted,
                  }"
                  class="w-full"
                />
                <small
                  v-if="
                    (v$.form.username.$invalid && submitted) ||
                    v$.form.username.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.username.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-6">
                <label>Password</label>
                <Skeleton v-if="isLoading" width="100%" height="35px" />
                <Password
                  v-else
                  v-model="form.password"
                  :toggleMask="true"
                  weakLabel="Password lemah"
                  mediumLabel="Password cukup kuat"
                  strongLabel="Password sangat kuat"
                  class="w-full"
                  input-class="w-full"
                />
                <small>* Isi jika hanya akan mengubah password</small>
              </div>
              <div class="field col-12 md:col-6">
                <label
                  :class="{ 'p-error': v$.form.email.$invalid && submitted }"
                  >Email</label
                >
                <Skeleton v-if="isLoading" width="100%" height="35px" />
                <InputText
                  v-else
                  v-model="v$.form.email.$model"
                  type="text"
                  :class="{ 'p-invalid': v$.form.email.$invalid && submitted }"
                  class="w-full"
                />
                <span v-if="v$.form.email.$error && submitted">
                  <span
                    id="email-error"
                    v-for="(error, index) of v$.form.email.$errors"
                    :key="index"
                  >
                    <small class="p-error">{{ error.$message }}</small>
                  </span>
                </span>
                <small
                  v-else-if="
                    (v$.form.email.$invalid && submitted) ||
                    v$.form.email.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.email.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-6">
                <label
                  :class="{ 'p-error': v$.form.phone.$invalid && submitted }"
                  >No. telepon</label
                >
                <Skeleton v-if="isLoading" width="100%" height="35px" />
                <InputText
                  v-else
                  v-model="v$.form.phone.$model"
                  type="number"
                  :class="{ 'p-invalid': v$.form.phone.$invalid && submitted }"
                  class="w-full"
                />
                <small
                  v-if="
                    (v$.form.phone.$invalid && submitted) ||
                    v$.form.phone.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.phone.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-4">
                <label>User Type</label>
                <Skeleton v-if="isLoading" width="100%" height="35px" />
                <InputText
                  v-else
                  v-model="form.type"
                  type="text"
                  class="w-full"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>Status</label>
                <Skeleton v-if="isLoading" width="100%" height="35px" />
                <InputText
                  v-else
                  v-model="form.status"
                  type="text"
                  class="w-full"
                  disabled
                />
              </div>
              <div class="field col-12 lg:col-4">
                <label>Approved</label>
                <Skeleton v-if="isLoading" width="100%" height="35px" />
                <InputText
                  v-else
                  v-model="form.approved"
                  type="text"
                  disabled
                  class="w-full"
                />
              </div>
            </div>
            <div class="flex justify-content-end">
              <Skeleton v-if="isLoading" width="110px" height="35px" />
              <Button
                v-else
                :loading="isLoadingSave"
                type="submit"
                label="Simpan"
                icon="pi pi-check"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required, email } from '@vuelidate/validators'
import Password from 'primevue/password'
import ProfileService from '@/services/ProfileService'
import errorHandler from '@/helpers/error-handler'

export default {
  name: 'Profile',
  components: {
    Password,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      profileService: null,
      form: {
        name: '',
        username: '',
        password: '',
        email: '',
        phone: '',
        type: '',
        status: '',
        approved: '',
      },
      submitted: false,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      initialValue: null,
    }
  },
  created() {
    this.profileService = new ProfileService()
  },
  mounted() {
    this.isLoading = true
    this.profileService
      .getUserProfile()
      .then((res) => {
        if (res.data.status === 200) {
          this.form = res.data.data
          this.initialValue = Object.assign({}, res.data.data)
        }
      })
      .catch((err) => {
        errorHandler(err, 'Profile', this)
      })
      .finally(() => (this.isLoading = false))
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      const profileForm = Object.assign({}, this.form)

      // hapus property dari request jika value tidak berubah
      if (this.initialValue.phone === this.form.phone) {
        delete profileForm.phone
      }

      if (this.initialValue.username === this.form.username) {
        delete profileForm.username
      }

      if (this.initialValue.email === this.form.email) {
        delete profileForm.email
      }

      if (!this.form.password) {
        delete profileForm.password
      }

      this.isLoadingSave = true
      this.profileService
        .updateUserProfile(profileForm)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Profile',
              detail: 'Data berhasil disimpan.',
              life: 3000,
            })
          }
        })
        .catch((err) => {
          errorHandler(err, 'Profile', this)
        })
        .finally(() => (this.isLoadingSave = false))
    },
  },
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
        username: {
          required: helpers.withMessage('Username harus diisi.', required),
        },
        email: {
          required: helpers.withMessage('Email harus diisi.', required),
          email: helpers.withMessage('Format email tidak valid.', email),
        },
        phone: {
          required: helpers.withMessage('No. telepon harus diisi.', required),
        },
      },
    }
  },
}
</script>
